export type Environment = {
  api_url: string;
  media_url: string;
  facebook_app_id: string;
  google_app_id: string;
  img_proxy_url: string;
  img_proxy_key: string;
  img_proxy_salt: string;
  website_id: string;
  tracking_domains: string[];
  site_url: string;
}

export const environment = {
  production: true,
  api_url: '/',
  media_url: 'https://media.poetica.pl/x/%s/plain/user-data/',
  img_proxy_url: "https://media.poetica.pl",
  img_proxy_key: "1e53c94eb5f02893c9c9dd8c99b7c4e3ea9dd820d89d1196eea1b603e444c21267f4cb3c3901140515fb7ebfe557502fa66d5ffa9212e134d70e6e2e77bd44c9",
  img_proxy_salt: "87b756734ac42122bb5ab4285fe06f80607e4cbabb09850b1d08cecf2a50d1f47545f16c91eb42553b8ba97b475788c9ddfeaac00273c15a782a9f039a627fdb",
  facebook_app_id: '1029108998507373',
  google_app_id: '963970666124-ngcg7ldi2pdflm2p1nsjn0t7rsacgqb3.apps.googleusercontent.com',
  website_id: "31b20c4c-9e27-49fc-be31-2c6e7b01a42f",
  tracking_domains: ["poetica.pl"],
  site_url: "https://poetica.pl"
} as Environment
