import {inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import {DOCUMENT, isPlatformBrowser} from "@angular/common";
import {RESPONSE} from "../express.tokens";
import {Router} from "@angular/router";
import {environment} from "../environments/environment";

export interface PageMetadata {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  type?: string;
  url?: string;
  author?: string;
}

export const DEFAULT_PAGE_METADATA: PageMetadata = {
  title: "Portal dla młodych autorów. Wiersze, wierszyki, poezja, opowiadania",
  description: "Poetica.pl - Portal dla młodych autorów. Wiersze, opowiadania, wierszyki, poezja, proza",
  keywords: "wiersze,wierszyki,poezja,opowiadania,miłosne,romantyczne,milosne,wesołe,na dobranoc,dla dzieci,o życiu",
  image: "https://media.poetica.pl/tErPAiye7M1FKocbAXX8sP0SRL1H5JmZULn8VlU4Zqs/rs::640/sm:true/fq:jpeg:75:png:75:avif:60:webp:60/plain/user-data/default/core/hero-image.jpg",
  type: "website",
  url: "https://poetica.pl",
  author: "Łukasz Pospiech"

}

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  private _doc = inject(DOCUMENT)
  private _response = inject(RESPONSE, {optional: true})
  private _platformId = inject(PLATFORM_ID)
  private _titleService = inject(Title)
  private _metaService = inject(Meta)
  private _router = inject(Router)

  public setMetadata(metadata: PageMetadata) {
    this.title(metadata.title || DEFAULT_PAGE_METADATA.title!)
    this.keywords(metadata.keywords || DEFAULT_PAGE_METADATA.keywords!)
    this.description(metadata.description || DEFAULT_PAGE_METADATA.description!)
    this.author(metadata.author || DEFAULT_PAGE_METADATA.author!)
    this.image(metadata.image || DEFAULT_PAGE_METADATA.image!)
    this.url();
  }

  private cutText(text: string, length: number, addon: string = '') {
    text.replace('&nbsp;', '')
    text = text.trim();
    text = text.replace(/(<([^>]+)>)/gi, "")

    if (text.length + addon.length <= length) {
      text = text + addon
      return text.trim()
    }

    if (text.length <= length) {
      return text.trim()
    }

    return text.substring(0, length).trim();
  }

  public title(title: string) {
    const addon = ' | Poetica.pl'
    this._titleService.setTitle(title + addon)
    this._metaService.updateTag({property: 'og:title', content: this.cutText(title, 90, addon)}, "property='og:title'");
    this._metaService.updateTag({
      property: 'twitter:title',
      content: this.cutText(title, 70, addon)
    }, "property='twitter:title'");
  }

  public author(author: string) {
    this._metaService.removeTag("name='author'")
    this._metaService.addTag({name: 'author', content: author});
  }

  public url() {
    let currentUrl = environment.site_url + this._router.url;
    this._metaService.updateTag({property: "og:url", content: currentUrl}, "property='og:url'")
    this.canonicalUrl(currentUrl);
  }

  public keywords(keywords: string) {
    this._metaService.addTag({name: 'keywords', content: keywords});
  }

  public description(description: string) {
    this._metaService.updateTag({name: 'description', content: this.cutText(description, 250)}, "name='description'");
    this._metaService.updateTag({
      property: 'og:description',
      content: this.cutText(description, 250)
    }, "property='og:description'");
    this._metaService.updateTag({
      property: 'twitter:description',
      content: this.cutText(description, 200)
    }, "property='twitter:description'");
  }

  public image(image: string) {
    //TODO: add default image for poetica.pl
    this._metaService.updateTag({property: "og:image", content: image}, "property='og:image'")
    this._metaService.updateTag({property: "twitter:image", content: image}, "property='twitter:image'")
  }

  public type(type: string) {
    this._metaService.updateTag({property: 'og:type', content: type}, "property='og:type'")
  }

  public notFound() {
    if (!isPlatformBrowser(this._platformId)) {
      this._response?.status(404);
    }
  }

  public canonicalUrl(url: string) {
    const head = this._doc.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null = this._doc.querySelector(`link[rel='canonical']`) || null

    if (element == null) {
      element = this._doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', url)
  }
}

// https://github.com/Coccodrillo/s3imageserver
//
// article - Namespace URI: https://ogp.me/ns/article#
//
//   article:published_time - datetime - When the article was first published.
//   article:modified_time - datetime - When the article was last changed.
//   article:expiration_time - datetime - When the article is out of date after.
//   article:author - profile array - Writers of the article.
//   article:section - string - A high-level section name. E.g. Technology
// article:tag - string array - Tag words associated with this article.
// profile - Namespace URI: https://ogp.me/ns/profile#
//
//   profile:first_name - string - A name normally given to an individual by a parent or self-chosen.
//   profile:last_name - string - A name inherited from a family or marriage and by which the individual is commonly known.
//   profile:username - string - A short unique string to identify them.
//   profile:gender - enum(male, female) - Their gender.
